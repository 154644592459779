export const landingPageQuery = `
  ...,
  content {
    main {
      ...,
      hero {
        ...,
        link {
          ...,
          link->
        },
        media {
          ...,
          image {
            ...,
            asset->
          }
        },
        mobileMedia {
          ...,
          image {
            ...,
            asset->
          }
        }
      },	
      modules[] {
        ...,
        _type == 'countdownModule' => {
          ...
        },
        _type == 'copyModule' => {
          ...
        },
        _type == 'postModule' => {
          ...,
          post->
        },
        _type == 'imageModule' => {
          ...,
          image {
            asset->
          }
        }
      }
    }
  }
`;

export const projectQuery = `
  ...,
  content {
    ...,
    main {
      ...,
      artistCredits[] {
        ...,
        artists[]->
      },
      tags[]->,
      media {
        ...,
        image {
          ...,
          asset->
        },
        threeUpVideo[] {
          ...,
          posterImage {
            ...,
            asset->
          }
        }
      },
      body[] {
        ...,
        _type == 'projectMediaModule' => {
          ...,
          media {
            ...,
            image {
              ...,
              asset->
            },
            threeUpVideo[] {
              ...,
              posterImage {
                ...,
                asset->
              }
            }
          }
        },
        _type == 'projectCopyModule' => {
          ...,
        }
      }
    }
  }
`;
