import React, { useState, useEffect } from "react";
import { Router } from "@reach/router";
import { Loading } from "src/components/loading";
import LandingPage from "src/templates/landing-page";
import Project from "src/templates/project";

import { landingPageQuery, projectQuery } from "src/api/previewQueries";

const sanityClient = require("@sanity/client");

const client = sanityClient({
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: process.env.GATSBY_SANITY_DATASET,
  token: process.env.GATSBY_SANITY_PREVIEW_TOKEN,
  useCdn: false, // `false` if you want to ensure fresh data
});

const PreviewPage = ({ documentId }) => {
  const [pageType, setPageType] = useState(null);
  const [pageData, setPageData] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [query, setQuery] = useState(null);

  const queryType = `*[_id == "${documentId}"] {
			...,
		}`;

  const queryPreviewLandingPage = `*[_id == "${documentId}" ] {
			${landingPageQuery}
    }`;

  const queryPreviewProject = `*[_id == "${documentId}" ] {
			${projectQuery}
		}`;
  console.log(documentId);
  // First set query by document type
  useEffect(() => {
    client
      .fetch(queryType)
      .then(response => {
        console.log(response);
        setPageType(response[0]._type);
        switch (response[0]._type) {
          case "landingPage":
            setQuery(queryPreviewLandingPage);
            break;

          case "project":
            setQuery(queryPreviewProject);
            break;
          default:
            return;
        }
      })
      .catch(error => {
        console.log("problem", error);
      });
  }, [documentId, queryPreviewLandingPage, queryType]);

  // Then fetch document data
  useEffect(() => {
    if (query) {
      client
        .fetch(query)
        .then(response => {
          setPageData(response[0]);
        })
        .catch(error => {
          console.log("problem", error);
        });
    }
  }, [query]);

  // Finally, subscribe to query for live updates
  useEffect(() => {
    setSubscription(
      client.listen(query, {}, { includeResult: false }).subscribe(update => {
        const timeout = setTimeout(() => {
          client.fetch(query).then(response => {
            setPageData(response[0]);
          });
        }, 1000);
      })
    );

    // // Unsubscribe from query
    // return function unsubscribe() {
    // 	if (subscription) {
    // 		subscription.unsubscribe();
    // 	}
    // };
  }, [query]);

  if (pageData) {
    switch (pageType) {
      case "landingPage":
        console.log(pageData.content);
        return <LandingPage pageContext={pageData.content} />;
      case "project":
        console.log(pageData.content);
        return <Project pageContext={pageData.content} />;
      default:
        break;
    }
  } else {
    return <Loading />;
  }
};

const Preview = () => {
  return (
    <Router>
      <PreviewPage path="/preview/:documentId" />
    </Router>
  );
};

export default Preview;
